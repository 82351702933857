import { css, styled, up } from '@smooth-ui/core-sc';

export default styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  transition: all 0.3s ease 0s;

  ${up(
    'lg',
    css`
      display: flex;
      justify-content: flex-end;
      text-align: center;

      & > li {
        display: inline-block;
        position: relative;
      }

      li > ul {
        display: none;
      }
      li:hover > ul,
      li > ul:hover {
        display: block;
      }
    `
  )}
`;
